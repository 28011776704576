import { gql, useQuery } from '@apollo/client';
import { 
  FormControlLabel,
  Paper,
  TextField,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Pagination,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead, } from '@mui/material';
import { StyledTableRow } from 'src/components/StyledTable';
import { format } from 'date-fns';
import { useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import { useLocation, useNavigate } from 'react-router-dom';

import style from './style.module.css';
import CancelButton from '../DeleteQueue/';

const jobListDetailKeys = {
  group_project_id: 'Group ID',
  title: 'Title',
  status: 'Status',
  create_by: 'Owner',
  project_started: 'Start Time',
  update_date: 'Last Update',
  actions: 'Actions',
};

interface jobListDetails {
  group_project_id:string,
  project_ids:string[],
  title:string,
  status:string,
  create_by:string,
  project_started:string,
  update_date:string
};

export default () => {
  const search = useLocation().search;
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(search);
  const sd = queryParams.get('sd');
  const ed = queryParams.get('ed');
  const [pageCount, setPageCount] = useState(parseInt(queryParams.get('l') || '10'));
  const [textFilter, setTextFilter] = useState(queryParams.get('q') || '');
  const [currentPage, setCurrentPage] = useState(parseInt(queryParams.get('p') || '1'));
  const [startDate, setStartDate] = useState<Date | null>(sd?new Date(parseInt(sd)):null);
  const [endDate, setEndDate] = useState<Date | null>(ed?new Date(parseInt(ed)):null);

  const d1 = startDate ? (endDate && endDate < startDate ? endDate : startDate).toISOString() : '';
  const d2 = endDate ? (startDate && startDate > endDate ? startDate : endDate).toISOString() : '';
  const filters = `startDate: "${d1}", endDate: "${d2}", matchText:"${textFilter}"`;

  const { data, error, loading } = useQuery(
    gql`
      query ListJobs {
        listJobs(page: ${currentPage - 1}, pageCount: ${pageCount}, ${filters}) {
          group_project_id
          project_ids
          project_started
          update_date
          status
          title
          create_by
        }
        countJobs(${filters}) {
          count
        }
      }
    `,
  );

  return (
    <div className={style.jobListContainer}>
    <h1>Job list</h1>
      <div className={style.filterWrapper}>
        <TextField
          className={style.textFilter}
          label="Text filter"
          onChange={(event) => {
            const filterValue = event.target.value.replace(/\\/g, '');
            setTextFilter(filterValue);
            filterValue?queryParams.set('q',event.target.value.replace(/\\/g, '')):queryParams.delete('q');
            queryParams.set('p','1');
            navigate({search: queryParams?.toString()});
            setCurrentPage(1);
          }}
          value={textFilter}
          variant="outlined"
        />
        <FormControlLabel
          control={
            <ReactDatePicker
              className={style.datePicker}
              dateFormat="dd/MM/yyyy HH:mm"
              isClearable={true}
              onChange={(date: Date | null) => {
                setStartDate(date);
                date?queryParams.set('sd',date?.getTime().toString()):queryParams.delete('sd');
                queryParams.set('p','1');
                navigate({search: queryParams?.toString()});
                setCurrentPage(1);
              }}
              placeholderText="Select a date"
              selected={startDate}
              shouldCloseOnSelect={false}
              showTimeSelect
            />
          }
          label="Start Date:"
          labelPlacement="start"
        />
        <FormControlLabel
          control={
            <ReactDatePicker
              className={style.datePicker}
              dateFormat="dd/MM/yyyy hh:mm a"
              isClearable={true}
              onChange={(date: Date | null) => {
                setEndDate(date);
                date?queryParams.set('ed',date?.getTime().toString()):queryParams.delete('ed');
                queryParams.set('p','1');
                navigate({search: queryParams?.toString()});
                setCurrentPage(1);
              }}
              placeholderText="Select a date"
              selected={endDate}
              showTimeSelect
            />
          }
          label="End Date:"
          labelPlacement="start"
        />
        <FormControl sx={{ m: 1, minWidth: 60 }}>
          <InputLabel>Results</InputLabel>
          <Select
            value={pageCount}
            label="Results"
            inputProps={{
              name: 'results',
            }}
            onChange={(event) => {
              const results=(event.target.value||'10') as string;
              setPageCount(parseInt(results));
              queryParams.set('l',results);
              queryParams.set('p','1');
              navigate({search: queryParams?.toString()});
              setCurrentPage(1);
            }}
          >
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={20}>20</MenuItem>
            <MenuItem value={50}>50</MenuItem>
          </Select>
        </FormControl>
      </div>
      {error && 'Error!'}
      {!loading && !error && (
        <Paper className={style.jobsTable}>
          <TableContainer>
            <Table aria-label="job list">
              <TableHead>
                <StyledTableRow>
                  {Object.values(jobListDetailKeys).map((title) => (
                    <TableCell key={title}>{title}</TableCell>
                  ))}
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {(data.listJobs || []).map((row: jobListDetails) => (
                  <StyledTableRow key={`${row.group_project_id}-row`}>
                    {(['group_project_id', 'title'] as const).map((field) => (
                      <TableCell key={`${field}-cell`} onClick={()=>navigate(`/job/id/${row.group_project_id}`)}>
                        {row[field]}
                      </TableCell>
                    ))}
                    <TableCell onClick={()=>navigate(`/job/id/${row.group_project_id}`)}>
                      {(row.status.substring(1,row.status.length-1)).split(", ").map(status => {return status.substring(0,40)}).join("\n")}
                    </TableCell>
                    <TableCell onClick={()=>navigate(`/job/id/${row.group_project_id}`)}>
                      {row.create_by}
                    </TableCell>
                    <TableCell onClick={()=>navigate(`/job/id/${row.group_project_id}`)}>
                      {format(new Date(`${row.project_started}+0000`), 'dd/MM/yyyy HH:mm:ss')}
                    </TableCell>
                    <TableCell onClick={()=>navigate(`/job/id/${row.group_project_id}`)}>
                      {format(new Date(`${row.update_date}+0000`), 'dd/MM/yyyy HH:mm:ss')}
                    </TableCell>
                    <TableCell>
                      <CancelButton title={row.title} id={row.group_project_id} status={row.status} />
                    </TableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      )}
      {data ? (
        <Pagination
          boundaryCount={3}
          className={style.pagination}
          count={Math.max(1, Math.ceil(data.countJobs[0].count / pageCount))}
          onChange={(event, page) => {
            queryParams.set('p',page.toString());
            navigate({search: queryParams?.toString()});
            setCurrentPage(page);
          }}
          page={currentPage}
          siblingCount={5}
        />
      ) : (
        'Loading ...'
      )}
    </div>
  );
};
