import { gql, useQuery } from '@apollo/client';
import { Paper, IconButton, Collapse, Button } from '@mui/material';
import { ExpandLess, ExpandMore, FilterNone } from '@mui/icons-material';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import { StyledTableRow } from 'src/components/StyledTable';
import { format } from 'date-fns';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import ModellingCancel from '../ModellingCancel';
import ModellingDelete from '../ModellingDelete';
import Highlight, { defaultProps } from "prism-react-renderer";

import { ModelData } from 'src/util';
import style from './style.module.css';

export default () => {
  const environment = process.env.REACT_APP_ENVIRONMENT;
  const { modelling_id } = useParams<{ modelling_id: string }>();
  const [openWeather, setOpenWeather] = useState(false);
  const [openAthena, setOpenAthena] = useState(false);

  const handleWeatherClick = () => {
    setOpenWeather(!openWeather);
  };

  const handleAthenaClick = () => {
    setOpenAthena(!openAthena);
  };

  const { data, error, loading } = useQuery(
    gql`
      query {
        getModelRun(modelling_id: "${modelling_id}") {
          title
          create_date
          modelling_id
          status
          wfb
          suppression
          likelihood
          old_house_loss
          fuel_hazard
          execution_arn
          project_id
          weather
          grid
          firehistory
          fuellayer
          fueltype
          create_by
        }
      }
    `,
  );

  const modelData = (data?.getModelRun[0] || undefined) as ModelData;

  interface weatherInterface {
    project_id:string,
    weather:string,
  }

  const getChars = (text:string) => {
    return (text.match(/[^0-9]/g) || []).join('');
  }

  const getInteger = (text:string) => {
    return parseInt((text.match(/[0-9]/g) || [0]).join(''));
  }

  const getConfiguration = () => {
    if (modelData.wfb) {
      return "worst";
    }
    if (modelData.likelihood) {
      return "Guaranteed Ignition";
    }
    if (modelData.suppression) {
      return "Uncontained Fire";
    }
    return "Standard Run";
  }

  const getSortedWeather = (project_ids:string[], weather:string[]) => {
    const weatherObject = project_ids.map((value, index) => ({project_id:value, weather:getFileName(weather[index])})) as weatherInterface[];
    const sortedWeather = weatherObject.sort((a,b) => {
      return getChars(a.weather) > getChars(b.weather) ? 1 :
      getChars(a.weather) === getChars(b.weather) ??
      getInteger(a.weather) - getInteger(b.weather) ? 1 : -1;
    });
    return sortedWeather;
  };

  const modelDatabase = (title:string, modelling_id:string) => {
    const athena_database_pre = environment === "test" ? "test_bn_" : "bn_";
    const replace_title = title.replace(/[^A-Za-z0-9]/g,'_');
    return `${athena_database_pre}${replace_title}_${modelling_id}`;
  }

  const getAthenaSQL = (modelDatabase:string) => {
    return `SELECT * FROM ${modelDatabase}.bn_output limit 10;\n
SELECT * FROM ${modelDatabase}.bn_ignition_summary limit 10;\n
SELECT * FROM ${modelDatabase}.bn_district_summary limit 10;\n
SELECT * FROM ${modelDatabase}.bn_region_summary limit 10;\n`;
  }

  const getFileName = (bucket:string) => {
    return bucket.split('/').pop();
  };
  if (loading || error) {
    return (
      <div>
        {loading && 'Loading ...'}
        {error && modelling_id && (<>
          Error!          
          <ModellingDelete
            title='Error'
            modelling_id={modelling_id}
            status='Error'
          />
        </>)}
      </div>
    )
  }

  return (
      <div>
        {modelData ? (
            <>
              <div className={style.modellingHeader}>
                <h1>{modelData.title}</h1>
                <div className={style.buttons}>
                  <ModellingCancel
                    title={modelData.title}
                    modelling_id={modelData.modelling_id}
                    execution_arn={modelData.execution_arn}
                    status={modelData.status}
                  />
                  <ModellingDelete
                    title={modelData.title}
                    modelling_id={modelData.modelling_id}
                    status={modelData.status}
                  />
                </div>
              </div>
              <Paper className={style.modellingTable}>
                <TableContainer>
                  <Table>
                    <TableHead>
                      <StyledTableRow>
                        <TableCell>Modelling ID</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell>Owner</TableCell>
                        <TableCell>Create Date</TableCell>
                      </StyledTableRow>
                    </TableHead>
                    <TableBody>
                      <StyledTableRow>
                        <TableCell>{modelData.modelling_id}</TableCell>
                        <TableCell>{modelData.status}</TableCell>
                        <TableCell>{modelData.create_by}</TableCell>
                        <TableCell>{format(new Date(`${modelData.create_date}+0000`), 'dd/MM/yyyy HH:mm:ss')}</TableCell>
                      </StyledTableRow>
                      <StyledTableRow>
                        <TableCell colSpan={4}><b>Inputs</b></TableCell>
                      </StyledTableRow>
                      <StyledTableRow>
                        <TableCell colSpan={4}>
                          <div className={style.fireInputs}>
                            <div><b>Ignition Grid:</b> {getFileName(modelData.grid)}</div>
                            <div><b>Fire History:</b> {getFileName(modelData.firehistory)}</div>
                            <div><b>Fuel Layer:</b> {getFileName(modelData.fuellayer)}</div>
                          </div>
                        </TableCell>
                      </StyledTableRow>
                      <StyledTableRow>
                        <TableCell colSpan={4}>
                          <div className={style.fireInputs}>
                            <div><b>Configuration:</b> {getConfiguration()}</div>
                            <div><b>Fuel Hazard File:</b> {modelData.fuel_hazard}</div>
                          </div>
                        </TableCell>
                      </StyledTableRow>
                      <StyledTableRow>
                        <TableCell colSpan={4} onClick={handleWeatherClick}>
                          <b>Project Weather</b>
                          {openWeather ?
                            <IconButton><ExpandLess /></IconButton> :
                            <IconButton><ExpandMore /></IconButton> }
                        </TableCell>
                      </StyledTableRow>
                      <StyledTableRow>
                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
                          <Collapse in={openWeather} timeout="auto" unmountOnExit>
                            {(getSortedWeather(modelData.project_id,modelData.weather)).map((value) => {
                              return (
                                <div className={style.detailHeader} key={`weather-${value.project_id}`}>
                                  <div><b>Project ID:</b> {value.project_id}</div>
                                  <div><b>Weather:</b> {value.weather}</div>
                                </div>
                              )
                            })}
                          </Collapse>
                        </TableCell>
                      </StyledTableRow>
                      {modelData.status === 'Bayes Net Complete' && (
                        <>
                          <StyledTableRow>
                            <TableCell colSpan={4} onClick={handleAthenaClick}>
                              <b>Database Queries</b>
                              {openAthena ?
                                <IconButton><ExpandLess /></IconButton> :
                                <IconButton><ExpandMore /></IconButton> }
                            </TableCell>
                          </StyledTableRow>
                          <StyledTableRow>
                            <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={4}>
                              <Collapse in={openAthena} timeout="auto" unmountOnExit>
                                <div className={style.codeBlock}>
                                  <Button
                                    variant="contained"
                                    endIcon={<FilterNone />}
                                    onClick={()=>{navigator.clipboard.writeText(getAthenaSQL(modelDatabase(modelData.title, modelData.modelling_id)));}}>
                                    Copy
                                  </Button>
                                  <Button
                                    target="_blank"
                                    variant="contained"
                                    href={`https://ap-southeast-2.console.aws.amazon.com/athena/home?region=ap-southeast-2#preview/${modelDatabase(modelData.title, modelData.modelling_id)}/bn_output`}>
                                    Load Athena Query in AWS
                                  </Button>
                                  <Highlight {...defaultProps} code={getAthenaSQL(modelDatabase(modelData.title, modelData.modelling_id))} language="sql">
                                      {({ className, style, tokens, getLineProps, getTokenProps }) => (
                                        <pre className={className} style={style}>
                                          {tokens.map((line, i) => (
                                            <div {...getLineProps({ line, key: i })}>
                                              {line.map((token, key) => (
                                                <span {...getTokenProps({ token, key })} />
                                              ))}
                                            </div>
                                          ))}
                                        </pre>
                                      )}
                                  </Highlight>
                                </div>
                              </Collapse>
                            </TableCell>
                          </StyledTableRow>
                        </>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Paper>
            </>
          ) : (
            'No data found for this ID!'
          )}
      </div>
    );
};
