import { gql, useQuery } from '@apollo/client';
import {
  Button,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
} from '@mui/material';
import { format } from 'date-fns';
import { Link, useParams } from 'react-router-dom';
import { Fragment } from 'react';
import AssetSelection from 'src/components/AssetSelection';
import FileUploadsList from 'src/components/FileUploadsList';
import { StyledTableRow } from 'src/components/StyledTable';
import {
  jobDetailKeys,
  JobDetailKeysExtended,
  jobFieldKeys,
  JobFieldsType,
  assetFieldKeys,
  postProcessingKeys,
  PostProcessingSelection,
  PostProcessingAssets,
} from 'src/util';
import PostProcessingList from 'src/components/PostProcessingList';
import JobConfiguration from '../JobConfiguration';

import CancelButton from '../DeleteQueue/'
import DeleteJobButton from '../DeleteJob/'
import style from './style.module.css';

export default () => {
  const { jobId } = useParams<{ jobId: string }>();
  const { data, error, loading } = useQuery(
    gql`
      query {
        getJobDBStatus(jobId: "${jobId}") {
          project_started
          projectname
          status
          actual_points
          expected_points
          error_points
          errors
          nfh
          nfh_id
          simulation_weather
          simulation_master_grid
          simulation_firehistory
          simulation_fuellayer
          simulation_fueltype
          create_by
        }
        getJobMetadataFile(jobId: "${jobId}") {
          statusCode
          body
        }
      }
    `,
    { fetchPolicy: "no-cache" },
  );

  const jobDBData = (data?.getJobDBStatus || []) as Array<
    { [key in keyof typeof JobDetailKeysExtended]: string }
  >;
  let jobStatuses = "" as string;
  const job_ids = [] as string[];
  jobDBData.forEach((jobData) => { jobStatuses += jobData.status; job_ids.push(jobData.projectname) })
  const jobMetadata = data?.getJobMetadataFile?.body
    ? (JSON.parse(data.getJobMetadataFile.body) as { [key: string]: any })
    : undefined;

  if (loading || error) {
    return (
      <div>
        {loading && 'Loading ...'}
        {error && 'Error!'}
      </div>
    )
  }

  if (!jobDBData || !jobMetadata) {
    return (
      <div>
        {`No data found for id: ${jobId}`}
      </div>
    )
  }

  const postProcessingSelection = Object.keys(postProcessingKeys).reduce(
    (acc, key) => ({ ...acc, [key]: jobMetadata[key] }),
    {} as PostProcessingSelection,
  );

  const postProcessingAssets = Object.keys(assetFieldKeys)
  .filter((key) => key in jobMetadata)
  .reduce(
    (acc, key) => ({ ...acc, [key]: [{
      ...jobMetadata[key],
      checked: true,
      title: jobMetadata[key].key.split('/')[1],
    }] }),  
    {} as PostProcessingAssets,
  );

  return (
    <div>
      {(postProcessingSelection && jobId && postProcessingAssets ? (
          <>
            <div className={style.jobHeader}>
              <h1>{jobMetadata.title}</h1>
              <div className={style.buttons}>
                <CancelButton title={jobMetadata.title} id={jobId} status={jobStatuses} />
                <DeleteJobButton title={jobMetadata.title} project_ids={job_ids} status={jobStatuses} redirect={true} />
                <Button
                  color="primary"
                  component={Link}
                  to={`/job/new/copyFrom/${jobId}`}
                  rel="noreferrer"
                  variant="contained"
                >
                  Clone job
                </Button>
              </div>
            </div>
            <div className={style.settings}>
              <JobConfiguration 
                matchFuel={jobMetadata["matchFuel"] === undefined || jobMetadata["matchFuel"]}
                wfb={jobMetadata["noFireHistory"]}
                keepKMZEnabled={jobMetadata["keepKMZEnabled"]}
                simulationDate={jobMetadata["fireHistory"][0]["burnTimes"][0]["date"]}
              />
              <div className={style.ids}>
                <div className={style.groupId}>
                  <b>Group ID:</b> {jobId}
                  <br /><b>Owner:</b> {jobDBData[0].create_by}
                </div>
                {jobDBData[0].nfh_id && (
                  <div className={style.nfhId}>
                    <b>WFB Run: </b>
                    <Link
                      to={`/job/id/${jobDBData[0].nfh_id.substring(0,32)}`}
                    >{jobDBData[0].nfh_id.substring(0,32)}
                    </Link>
                  </div>
                )}
              </div>
            </div>
            <Paper className={style.jobsTable}>
              <TableContainer>
                <Table aria-label="job list">
                  <TableHead>
                    <StyledTableRow>
                      {Object.values(
                        Object.fromEntries(
                          Object.entries(jobDetailKeys).filter(([key]) => key !== 'create_by')
                        )
                      ).map((title) => (
                        <TableCell key={`${title}-header`}>{title}</TableCell>
                      ))}
                    </StyledTableRow>
                  </TableHead>
                  <TableBody>
                    {jobDBData.map((row) => (
                      <Fragment key={row.projectname}>
                        <StyledTableRow>
                          {(['projectname', 'status', 'expected_points', 'actual_points', 'error_points'] as const).map((field) => (
                            <TableCell key={`${field}-value`}>
                              <div className={style.cellLink}>{row[field]}</div>
                            </TableCell>
                          ))}
                          <TableCell>
                            <div className={style.cellLink}>
                              {format(new Date(`${row.project_started}+0000`), 'dd/MM/yyyy HH:mm:ss')}
                            </div>
                          </TableCell>
                        </StyledTableRow>
                        <StyledTableRow>
                          <TableCell colSpan={6}>
                            <div className={style.subTitle}>Inputs:</div>
                            <div className={style.cellFlex}>
                            {(['simulation_weather','simulation_master_grid','simulation_firehistory','simulation_fuellayer','simulation_fueltype'] as const).map((field) => (
                                <div key={`${field}-value`}>
                                  <b>{JobDetailKeysExtended[field]}:</b> {!!row[field] && row[field].split('/').pop()}
                                </div>
                            ))}
                            </div>
                          </TableCell>
                        </StyledTableRow>
                        {row.errors != null && (
                          <StyledTableRow>
                            <TableCell colSpan={6}>
                              <div className={style.subTitle}>Errors:</div>
                              <div className={style.cellErrors}>
                                {row.errors.replace("{\"","").replace("\"}","").split("\",\"").join('\n')}
                              </div>
                            </TableCell>
                          </StyledTableRow>
                        )}
                      </Fragment>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>

            <Paper className={style.uploadTabs}>
              <h3>File uploads for job</h3>
              <FileUploadsList
                data={Object.keys(jobFieldKeys).reduce(
                  (acc, key) => ({
                    ...acc,
                    [key]: jobMetadata[key].map((item: JobFieldsType) => ({
                      ...item,
                      checked: true,
                      ...(key === 'fireHistory' && item.key === undefined
                        ? { title: 'No Fire History' }
                        : { title: item.key.split('/')[1] }),
                    })),
                  }),
                  {} as any,
                )}
                uploadId={jobId}
                wfb={jobMetadata["noFireHistory"]}
              />

              <h3>Postprocessing options from JSON</h3>
              <PostProcessingList data={postProcessingSelection} />
              <AssetSelection
                postProcessingAssets={postProcessingAssets}
                postProcessing={postProcessingSelection}
              />
            </Paper>
          </>
        ) : (
          'No data found for this ID!'
        ))}
    </div>
  );
};
